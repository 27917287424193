@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
.skills-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    
    .skill-item {
        position: relative;
        margin: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          height: 6rem;
          width: 6rem;
        }
        
        .skill-name {
            margin-top: 0.5rem;
        }
        &:hover {
            animation: zoom-in-zoom-out 1s ease;
        }
    }
}