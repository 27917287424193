@import '../../colors.scss';

.vertical-timeline-element-content {
    @media (max-device-width: 480px) {
        margin-left: 7rem !important;
      }
   
}
.light {
    .exp-title {
        color: $primary;
    }
}
.dark {
    .exp-title {
        color: white;
    }
}

img {
    height: 5rem;
    width: 5rem;
    &.fidelity {
        width: 5.5rem;
    }
}