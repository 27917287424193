@import '../../colors.scss';
.light {
    .tab-container {
        div {
            strong {
                color: $primary-dark;
            }
        }
    }
}
.tab-container {
    padding: 2rem;
    @media (max-device-width: 480px) {
        padding: 1rem;
    }
    div {
        margin: 1rem 0;
    }
}
.MuiTabs-root {
    @media (max-device-width: 480px) {
        min-height: 0px !important;
    }
}
.light .MuiTab-root{
    color: $primary-dark !important;
}
.projects-container {
    width: 70rem;
    @media (max-device-width: 480px) {
        width: 45rem;
    }
}
.MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3 !important;
}

.MuiButtonBase-root {
    @media (max-device-width: 480px) {
        padding: 5px !important;
        min-width: 2rem !important;
        min-height: 2rem !important;
      }
}

.tabpanel {
    @media (max-device-width: 480px) {
        width: 45rem !important;
    }
}
.paper {
    @media (max-device-width: 480px) {
        height: 18rem !important;
    }
}