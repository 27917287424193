@import "./colors.scss";

html {
  scroll-behavior: smooth;
}
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: $primary-dark; }
}
@keyframes bounce {
  0% { top: 0; }
  50% { top: -0.2em; }
  70% { top: -0.3em; }
  100% { top: 0; }
}
.App {
  .light {
    // color: $primary !important;
    background: white !important;

  }
  .dark {
    
    background: #282c34 !important;
  }
  header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    .left-pane {
      color: $primary;
      font-weight: 600;
    }
    .right-pane {
      margin-right: 2rem;
      nav {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        align-items: center;
        a {
          text-decoration: none;
          font-weight: 600;
          padding-bottom: 0.5rem;
        }
      }
    }
  }
  .welcome-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15rem 21rem;
    @media (max-device-width: 480px) {
      margin: 8rem 5rem;
    }
    width: fit-content;

    .greeting {
      font-size: 4rem;
      margin-bottom: 0.5rem;
      border-right: .1em solid $primary-dark;
      animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
      white-space: nowrap;
      overflow: hidden;
    }
    .subtitle {
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }
    .description {
      font-size: 1.5rem;
    }
    .socials {
      margin: 2rem;
      display: flex;
      gap: 1rem;
      a {
        position: relative;
        &:hover {
          animation: bounce 0.5s infinite linear;
        }
      }
    }
    .scroll-down {
      animation: bounce 2s infinite 2s;
      transition: all 0.2s ease-in;
    }
    .resume {
      margin-bottom: 3rem;
      a {
        padding: 0.5rem;
        display: flex;
        border: 2px solid $primary;
        border-radius: 20px;
        &:hover {
          color: white;
          background-color: $primary;
          svg {
            position: relative;
            animation: bounce 0.5s infinite linear;
          }
        }
      }
    }
  }
  section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .vertical-timeline.vertical-timeline--two-columns:before {
      margin-left: 6px;
      background: lightblue;
    }
    .section-title {
      display: flex;
      font-size: 2rem;
      justify-content: center;
      margin: 2rem;
      
    }
  }
  .light {
    .greeting {
      color: $primary-dark;
    }
    .subtitle {
      color: $primary;
      border-bottom: 1px solid $primary;
    }
    .section-title {
      color: $primary-dark;
      border-bottom: 1px solid $primary;
    }
    .description {
      color: $primary;
    }
    nav {
      a {
        &:hover {
          color: $primary-dark !important;
          border-bottom: 1px solid $primary-dark;
        }
      }
    }
    a {
      color: $primary;
    }
  }
  .dark {
    .subtitle {
      border-bottom: 1px solid white;
    }
    color: white;
    .section-title {
      color: white;
      border-bottom: 1px solid white;
    }
    a {
      color: white;
    }
  }
}
